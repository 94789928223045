<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container container-padded">
      
      <div class="ms-auto">
        <a href="https://www.instagram.com/monicawaalwijkdecarvalho_art/" class="btn btn-insta p-0 me-3">
          <div class="d-flex align-items-center">
            <i class="bi bi-instagram iconLarge mb-0 me-1"></i> <span class="mt-0 text-primary fw-bolder text-insta">MWC Art</span>
          </div>
         
        </a>
        <a href="https://www.instagram.com/monomade_/" class="btn btn-insta p-0 me-2">
          <div class="d-flex align-items-center">
            <i class="bi bi-instagram iconLarge mb-0 me-1"></i> <span class="mt-0 text-primary fw-bolder text-insta">MonoMade</span>
          </div>  
        </a>

        
      </div>

      <button class="navbar-toggler  border-0 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav text-end ms-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/" v-on:click="hideMenu">Selected work</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/about" v-on:click="hideMenu">About</router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.monomade.nl">MonoMade</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div class="container container-padded">
    
    <div class="row">
      <div class="col-12 pt-3 pt-sm-4">
        <h1>Mónica<br>
          Waalwijk de Carvalho<br>
          <span class="text-primary artBold">Art</span>
        </h1>
      </div>
    </div>
  </div>
    <router-view />

</template>

<script>
export default {

methods: {
  hideMenu() {
    document.getElementById('navbarNavDropdown').classList.remove('show');
  }
}

}
</script>